import React,{ Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'

export default function FormSubmittedModal(status) {
  
    return (
        <div className="relative bg-white">
            <Transition.Root show={true} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={true} onClose={() => {}}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    <div>
                        {status === 200 ?
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-letsbike-blue">
                                <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                        : 
                            <div className="mx-auto opacity-80 flex items-center justify-center h-12 w-12 rounded-full bg-letsbike-red">
                                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                        }
                        <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            {status === 200 ? "Submission completed" : "Oh no!"}
                        </Dialog.Title>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                            {status === 200 
                            ? "Thank you for filling out the form. We will now process your information and get in touch with you as soon as your waypoint goes live!" 
                            : status === 422 ? "That token is invalid. Please double check the link we sent you, or get in touch with us via email if you did not expect this to happen." 
                            : "You have already submitted the form once. Did you do so by mistake or want to change something? Please get in touch with us directly via email."}
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                        <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-letsbike-red text-base font-medium text-white hover:bg-letsbike-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-letsbike-blue sm:text-sm"
                        onClick={() => {window.location.href = 'mailto:contact@letsbike.lu';}}
                        >
                            SEND US A MESSAGE
                        </button>
                    </div>
                    </div>
                </Transition.Child>
                </div>
            </Dialog>
            </Transition.Root>
        </div>
    )
  }