import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'


const fullConfig = resolveConfig(tailwindConfig)
const containerStyle = {
  width: typeof window === `undefined` ? 100 : window.innerWidth > parseInt(fullConfig.theme.screens.md,10) ? window.innerWidth/3 
  : window.innerWidth > parseInt(fullConfig.theme.screens.sm,10) ? window.innerWidth/1.3 :
  window.innerWidth/1.1,
  height: '400px'
};

const center = {
  lat: 49.795051, 
  lng:6.094648
};

function ReactMap(props) {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyA8i8jUqw2a7fwhpRHanDiM7Ouk-NZCmiI"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8.5}
      >
        
        <Marker
          position={props.position}
        />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(ReactMap)