import React, { Component, useEffect, useState } from "react"
import axios from "axios"
import InvalidToken from "./invalidToken";
import ReactMap from "./reactMap";
import { XIcon } from "@heroicons/react/solid";
import FormSubmittedModal from "./formSubmittedModal";
const submitForm = async (content,token,setContent) => {
  // const request = new XMLHttpRequest();
  // let response = await new Promise(resolve => {  
      const formData = new FormData();
      let mappedType = content.type;
      switch (content.type) {
        case "POI":
          mappedType = "poi";
          break;
        case "Restaurant":
          mappedType = "restaurant";
          break;
        case "Shop":
          mappedType = "shop";
          break;
        case "Hotel":
          mappedType = "hotel";
          break;
        case "Parking Lot":
          mappedType = "parkingLot";
          break;
        case "Water Station":
          mappedType = "waterStation";
          break;
        case "Repair Station":
          mappedType = "repairStation";
          break;
        case "Picture":
          mappedType = "picture";
          break;
        default:
          mappedType = "poi";
          break;
      }
      const data = {
        locale: "en",
        position:{
          "latitude" : content.position.lat,
          "longitude" : content.position.lng,
        },
        type: mappedType,
        contactDetails:{
          "email": content.email,
          "web": content.web,
          "phone": content.phone,
          "address": content.address
        },
        name: content.nameEN,
        description: content.descriptionEN,
        localizations: [
          {
            locale:"de",
            name: content.nameDE,
            description: content.descriptionDE,
          },
          {
            locale:"fr",
            name: content.nameFR,
            description: content.descriptionFR,
          }
        ]
      };
      if(content.coverPhoto !== undefined){
          formData.append(`files.titleMedia`, content.coverPhoto, content.coverPhoto.name);
      }
      if(content.media !== undefined){
          for (let i = 0; i < content.media.length; i++) {
              if(content.media[i] !== undefined){
                  const file = content.media[i];
                  formData.append(`files.media`, file, file.name);
              }
          }
      }

      formData.append('data', JSON.stringify(data));

    //   request.open('POST', `https://apiv2.letsbike.lu/waypoints/submit/${token}`, false);

    //   request.send(formData);
    //   console.log(request);
    // });
    let res = await axios({
      method: "post",
      url: `https://apiv2.letsbike.lu/waypoints/submit/${token}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data"},
    });
    console.log(res);
    setContent({...content, formStatus: res.status});
}

const formContent = (name,content,setContent,token) => {
    const handleSubmit = (event) => {
      event.preventDefault();
        submitForm(content,token,setContent);
    }
    return (
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
            <img
                className="xl:ml-28 lg:ml-10 md:ml-8 ml-8 mt-12 w-80 object-cover object-center"
                src="https://letsbike.lu/wp-content/uploads/2020/04/LetsBikeLogo_WithText@2x.png"
                alt=""
            />
          <div className="lg:absolute bg-auto bg-right lg:inset-y-0 lg:right-0 lg:w-1/2 bg-fixed" style={{backgroundImage: 'url(https://letsbike.lu/wp-content/uploads/2020/04/BikeCity-1.jpg)'}}>
            
          </div>
        </div>
        <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h1 className="mb-16 mt-12 text-4xl font-extrabold tracking-tight sm:text-4xl">{name}</h1>
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-3xl">Let's work together</h2>
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                  Please fill in the information below. 
                  It will be used inside the LETSBIKE App and the website in order to promote your business,
                  so make sure everything you enter is correct. Once the content is approved by our team, we will
                  get in touch with you via email.
              </p>
              <form action="#" method="POST" className="mt-9 grid grid-cols-3 gap-y-6 sm:gap-x-8">
                <div className="sm:col-span-1 col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    Waypoint Name (FR🇫🇷)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name-fr"
                      id="name-fr"
                      required={true}
                      value={content.nameFR}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              nameFR:event.target.value
                          })
                      }}
                      className="block border p-2 w-full shadow-sm sm:text-sm border-gray-300 hover:border-gray-400 focus:border-letsbike-blue focus:outline-none rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1 col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Waypoint Name (DE🇩🇪)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name-de"
                      id="name-de"
                      required={true}
                      value={content.nameDE}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              nameDE:event.target.value
                          })
                      }}
                      className="block border p-2 w-full shadow-sm sm:text-sm border-gray-300 hover:border-gray-400 focus:border-letsbike-blue focus:outline-none rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1 col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Waypoint Name (EN🇬🇧)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name-en"
                      required={true}
                      value={content.nameEN}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              nameEN:event.target.value
                          })
                      }}
                      id="name-en"
                      className="block border p-2 w-full shadow-sm sm:text-sm border-gray-300 hover:border-gray-400 focus:border-letsbike-blue focus:outline-none rounded-md"
                    />
                  </div>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-4 items-start border-gray-200 pt-5">
                    <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 mt-px pt-2">
                        Cover photo
                    </label>
                    <div className="mt-1 mt-0 col-span-2">
                        <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        {content.coverPhoto === undefined ?
                        <div className="space-y-1 text-center">
                            <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                            >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                                <span>Upload a file</span>
                                <input id="file-upload" value={content.coverPhoto} onChange={(e)=>{
                                    setContent({
                                        ...content,
                                        coverPhoto:e.target.files[0]
                                    })
                                }} name="file-upload" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </div>
                        : <div>
                            <span className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
                                {content.coverPhoto.name}
                                <button
                                type="button"
                                onClick={(e)=>{
                                    setContent({
                                        ...content,
                                        coverPhoto:undefined
                                    })
                                }}
                                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                                >
                                <span className="sr-only">Remove large option</span>
                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                </svg>
                                </button>
                            </span>
                            </div>
                        }
                        </div>
                    </div>
                </div>
                <div className="sm:col-span-1 col-span-3">
                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                        Type
                    </label>
                    <select
                        id="type"
                        name="type"
                        className="block border p-2 w-full shadow-sm sm:text-sm border-gray-300 hover:border-gray-400 focus:border-letsbike-blue focus:outline-none rounded-md"
                        required={true}
                        value={content.type}
                        onChange={(event) => {
                            setContent({
                                ...content,
                                type:event.target.value
                            })
                        }}
                    >
                        <option>POI</option>
                        <option>Restaurant</option>
                        <option>Shop</option>
                        <option>Hotel</option>
                        <option>Parking Lot</option>
                        <option>Water Station</option>
                        <option>Repair Station</option>
                        <option>Picture</option>
                    </select>
                </div>
                <div className="sm:col-span-1 col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="web" className="block text-sm font-medium text-gray-700">
                        Web
                    </label>
                    <span id="web-description" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="url"
                      name="web"
                      required={false}
                      value={content.web}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              web:event.target.value
                          })
                      }}
                      id="web"
                      className="block border p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1 col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                    </label>
                    <span id="email-description" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      required={false}
                      value={content.email}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              email:event.target.value
                          })
                      }}
                      id="email"
                      className="block border p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1 col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                      Phone
                    </label>
                    <span id="phone-description" className="text-sm text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      required={false}
                      autoComplete="tel"
                      value={content.phone}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              phone:event.target.value
                          })
                      }}
                      aria-describedby="phone-description"
                      className="block border p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                      Adress
                    </label>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="address"
                      name="address"
                      aria-describedby="address-description"
                      rows={3}
                      className="block p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border border-gray-300 rounded-md"
                      required={true}
                      value={content.address}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              address:event.target.value
                          })
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-3">
                   <ReactMap position={content.position}/>
                </div>
                <div className="sm:col-span-1 col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="lat" className="block text-sm font-medium text-gray-700">
                      Latitude
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lat"
                      id="lat"
                      required={true}
                      value={content.position.lat}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              position:{
                                  lat: parseFloat(event.target.value),
                                  lng: content.position.lng
                              }
                          })
                      }}
                      aria-describedby="lat-description"
                      className="block border p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="long" className="block text-sm font-medium text-gray-700">
                      Longitude
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="long"
                      id="long"
                      required={true}
                      value={content.position.lng}
                      onChange={(event) => {
                          console.log(event.target.value);
                          setContent({
                              ...content,
                              position:{
                                  lat: content.position.lat,
                                  lng: parseFloat(event.target.value)
                              }
                          })
                      }}
                      aria-describedby="long-description"
                      className="block border p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="description-en" className="block text-sm font-medium text-gray-700">
                      Description (FR🇫🇷)
                    </label>
                    <span id="description-fr-description" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="description-fr"
                      name="description-fr"
                      aria-describedby="description-fr-description"
                      rows={4}
                      className="block p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border border-gray-300 rounded-md"
                      required={true}
                      value={content.descriptionFR}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              descriptionFR:event.target.value
                          })
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="description-de" className="block text-sm font-medium text-gray-700">
                      Description (DE🇩🇪)
                    </label>
                    <span id="description-de-description" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="description-de"
                      name="description-de"
                      aria-describedby="description-en-description"
                      rows={4}
                      className="block p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border border-gray-300 rounded-md"
                      required={true}
                      value={content.descriptionDE}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              descriptionDE:event.target.value
                          })
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between">
                    <label htmlFor="description-en" className="block text-sm font-medium text-gray-700">
                      Description (EN🇬🇧)
                    </label>
                    <span id="description-en-description" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="description-en"
                      name="description-en"
                      aria-describedby="description-en-description"
                      rows={4}
                      className="block p-2 w-full shadow-sm sm:text-sm hover:border-gray-400 focus:border-letsbike-blue focus:outline-none border border-gray-300 rounded-md"
                      required={true}
                      value={content.descriptionEN}
                      onChange={(event) => {
                          setContent({
                              ...content,
                              descriptionEN:event.target.value
                          })
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-3 grid grid-cols-3 gap-4 items-start border-gray-200 pt-5">
                    <label htmlFor="media" className="block text-sm font-medium text-gray-700 mt-px pt-2">
                        Other media
                    </label>
                    <div className="mt-1 mt-0 col-span-2">
                        <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        {content.media === undefined || content.media.length === 0 ?
                            <div className="space-y-1 text-center">
                                <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                                >
                                <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                </svg>
                                <div className="flex text-sm text-gray-600">
                                <label
                                    htmlFor="files-upload"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                    <span>Upload files</span>
                                    <input id="files-upload" multiple={true} value={content.media} onChange={(e)=>{
                                        setContent({
                                            ...content,
                                            media:e.target.files
                                        })
                                    }} name="file-upload" type="file" className="sr-only" />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                            </div>
                            : <div>
                                {Array.from(content.media as FileList).map((med) => {
                                return(
                                    <div key={med.name}>
                                        <span className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
                                            {med.name}
                                            <button
                                            type="button"
                                            onClick={(e)=>{
                                                setContent({
                                                    ...content,
                                                    media:Array.from(content.media as FileList).filter(m => m !== med)
                                                })
                                            }}
                                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                                            >
                                            <span className="sr-only">Remove large option</span>
                                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                            </svg>
                                            </button>
                                        </span>
                                    </div>
                                )
                                })}
                            </div>
                        }
                        </div>
                    </div>
                </div>
                <div className="text-right col-span-3">
                  <button
                    type="submit"
                    onClick={(event)=>handleSubmit(event)}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-letsbike-red hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-letsbike-blue"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

const WaypointForm = (token: String) => {
    const [isLoading, setLoading] = useState(true);
    const [tokenName, setTokenName] = useState("not_found");
  const [content, setContent] = useState({
      formSubmitted:false,
      formStatus: -1,
      nameDE: "",
      nameFR: "",
      nameEN: "",
      address: "",
      type:"POI",
      web:"",
      email:"",
      phone:"",
      descriptionDE:"",
      descriptionFR:"",
      descriptionEN:"",
      position: {
            lat: 49.795051, 
            lng:6.094648
    }});

  useEffect(() => {
    if(isLoading){
        axios.get(`https://apiv2.letsbike.lu/waypoint-creation-tokens/verify/${token}`).then(res => {
            if(res.status === 200){
                setTokenName(res.data.name);
            }
            setLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return(
        <div className="bg-white">
            <div>Loading...</div>
        </div>);
  }
  if(content.formStatus !== -1){
    return(
    <div className="bg-white">
      {FormSubmittedModal(content.formStatus)}
    </div>)
  }
  else if(tokenName === "not_found"){
    return (
        <InvalidToken/>
    );
  }
  else{
    return formContent(tokenName,content,setContent,token);
  }
}

export default WaypointForm;
