import * as React from "react"
import { ArrowRightIcon, CloudDownloadIcon } from '@heroicons/react/solid'
import { MailIcon } from '@heroicons/react/solid'
import { useState } from "react";
import letsbikeBrochureDE from "../files/LETSBIKE-Advertisment-DE.pdf"
import letsbikeBrochureFR from "../files/LETSBIKE-Advertisment-FR.pdf"
import letsbikeBrochureEN from "../files/LETSBIKE-Advertisment-EN.pdf"

export default function NoToken() {
    const [showLanguages, setShowLanguages] = useState(false);
    return (
        <main>
          <div className="bg-white">
              <img
                    className="xl:ml-32 lg:ml-16 md:ml-8 ml-8 mt-12 w-80 object-cover object-center"
                    src="https://letsbike.lu/wp-content/uploads/2020/04/LetsBikeLogo_WithText@2x.png"
                    alt=""
                  />
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div className="bg-letsbike-red rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                  <div className="lg:self-center">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                      <span className="block">READY</span>
                      <span className="block">TO BECOME A PARTNER?</span>
                    </h2>
                    <p className="mt-4 mb-4 text-lg leading-6 text-white opacity-75">
                    Check out your options or get in touch with us
                    </p>
                    <button
                    onClick={() => setShowLanguages(!showLanguages)}
                          type="button"
                          className="mr-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-light rounded-md text-white hover:text-letsbike-blue bg-letsbike-blue hover:bg-letsbike-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-letsbike-blue"
                      >
                      <CloudDownloadIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                          Brochure
                      </button>
                      <button
                    onClick={() => {location.href = "mailto:contact@letsbike.lu"}}
                          type="button"
                          className="mr-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-light rounded-md text-white hover:text-letsbike-blue bg-letsbike-blue hover:bg-letsbike-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-letsbike-blue"
                      >
                      <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                          Contact
                      </button>
                      <h4 hidden={!showLanguages} className="mt-4 text-xl font-extrabold text-white sm:text-xl">
                          <a href={letsbikeBrochureDE} className="block hover:text-letsbike-blue">🇩🇪 DEUTSCH</a>
                          <a href={letsbikeBrochureFR} className="block hover:text-letsbike-blue">🇫🇷 FRANÇAIS</a>
                          <a href={letsbikeBrochureEN} className="block hover:text-letsbike-blue">🇬🇧 ENGLISH</a>
                      </h4>
                      <h4 hidden={showLanguages} className="mt-4 text-xl font-extrabold text-white sm:text-xl">
                          <a className="block text-letsbike-red">DEUTSCH</a>
                          <a className="block text-letsbike-red">FRANÇAIS</a>
                          <a className="block text-letsbike-red">ENGLISH</a>
                      </h4>
                  </div>
                </div>
                <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                  <img
                    className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                    src="https://letsbike.lu/wp-content/uploads/2020/04/Cover.jpg"
                    alt="App screenshot"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end xl:mr-32 lg:mr-16 md:mr-8 mr-8 -mt-8 mb-8 animate-pulse ">
              <a href="https://letsbike.lu">
                <ArrowRightIcon className="absolute h-6 w-6 text-letsbike-red" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-letsbike-red">VISIT LETSBIKE.LU</p>
              </a>
            </div>
          </div>
        </main>
      )
  }