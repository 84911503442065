import * as React from "react"
import NoToken from "../components/notoken"
import WaypointForm from "../components/waypointForm"
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'

// markup
const IndexPage = ({ location }) => {
 
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  if(token === null || token === ""){
    return NoToken();
  }
  return (
    <main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>LETSBIKE Waypoint Submission</title>
          <link rel="canonical" href="https://partner.letsbike.lu" />
          <link rel="icon" href={favicon} />
        </Helmet>
      {WaypointForm(token)}
    </main>
  )
}

export default IndexPage
