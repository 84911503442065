

module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        'letsbike-red': '#E20814',
        'letsbike-white': '#FFFFFF',
        'letsbike-blue': '#009AFD',
      },
      fontFamily: {
        'sans': ['Nobel'],
      }
    }
  },
  variants: {
    extend: {},
  },
  plugins: [],
}
